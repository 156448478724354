/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
 
import React from 'react';
import withRoot from './src/withRoot';

const WithRoot = withRoot(props => props.children);

export const wrapRootElement = ({ element }) => {
  return <WithRoot key={Math.random()}>{element}</WithRoot>;
};